.columnLabelContainer {
  display: flex;
  flex-direction: row;
  padding: 0 32px 0 32px;
  margin-bottom: 8px;

  .nameCol {
    flex: 1;
  }

  .typeCol {
    flex: 1;
    text-align: center;
  }

  .actionsCol {
    width: 64px;
  }
}

.activityListContainer {
  background-color: white;
  padding: 16px;
  border-radius: 6px;

  @media screen and (max-width: 768px) {
    padding: 4px;
  }

  .createButton {
    padding: 16px;
    border: 3px dashed #ccc;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #555;
    margin-bottom: 8px;

    .addIcon {
      margin-right: 8px;
    }
  }

  .activityList {
    .listItem:last-child {
      border: none;
    }
  }
}
