.columnLabelContainer {
  display: flex;
  flex-direction: row;
  padding: 0 32px 0 32px;
  margin-bottom: 8px;

  .nameCol {
    flex: 1;
  }

  .roleCol, .orgCol, .enrollmentCol {
    flex: 1;
    text-align: center;
  }

  .actionsCol {
    width: 64px;
  }
}

.courseListContainer {
  background-color: white;
  padding: 16px;
  border-radius: 6px;

  .courseList {
    .listItem:last-child {
      border: none;
    }
  }
}

.footer {
  padding: 0px;
  padding-top: 16px;
  display: flex;
  margin-top: 16px;
  border-top: 1px solid #eee;

  .leftActions {
    flex: 1;
    padding-left: 8px;

    .rowSelect {
      margin-left: 8px;
    }
  }

}