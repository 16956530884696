@import './reset.scss';
@import 'aws-amplify-overrides.scss';
@import 'common/styles/vars.scss';

html, body {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  margin: 0;
  padding: 0;
  color: $bongo-default-color;
}
