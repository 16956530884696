.columnLabelContainer {
  display: flex;
  flex-direction: row;
  padding: 0 32px 0 32px;
  margin-bottom: 8px;

  .nameCol {
    flex: 1;
  }

  .roleCol, .orgCol {
    flex: 1;
    text-align: center;
  }

  .actionsCol {
    width: 52px;
  }
}

.courseListContainer {
  background-color: white;
  padding: 16px;
  border-radius: 6px;
}