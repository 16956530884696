.container {
  height: calc(100vh - 64px);
}

.appBar {
  .actionsContainer {
    display: flex;

    .backButton {
      color: white;
      font-size: 24px;
    }
  }

  .username {
    font-size: 24px;
    margin-left: 16px;
  }
}

.errorMessage {
  margin-top: 64px;
  display: flex;
  justify-content: center;
  padding: 32px;
}