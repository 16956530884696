@import "@aws-amplify/ui-react/styles.css";
@import 'common/styles/vars.scss';

[data-amplify-authenticator] {
    height: 100vh;
    background: $bongo-page-gradient;
    max-width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    --amplify-colors-background-secondary: #e8e8e8;
    --amplify-components-tabs-item-active-border-color: white;
    --amplify-components-tabs-item-active-color: var(--bsa-theme-palette-primary);
    --amplify-components-tabs-item-focus-color: var(--bsa-theme-palette-primary);
    --amplify-components-text-color: var(--bsa-theme-palette-primary);
    --amplify-components-button-primary-background-color: var(--bsa-theme-palette-primary);
    --amplify-components-tabs-item-font-weight: 500;
  }
