.loadingCourses {
  padding: 0 15px 75px;
}

.noCourses {
  text-align: center;
  padding: 0 0 75px;
}

.courseCount {
  font-weight: bold;
  margin-right: 6px;
}