.content {
  padding: 24px;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;

    .titleContainer {
      flex: 1;
      color: #444;

      h1 {
        margin: 0;
      }
    }

    .headerActions {
      align-self: flex-start;
    }

    .closeButton {
      height: 48px;
      width: 48px;
    }
  }
}

.assignmentTypes {
  padding: 24px;

  display: grid;
  overflow: visible;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 24px;
  grid-row-gap: 24px;

  @media screen and (max-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  .assignmentItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    flex: 0 1 calc(25% - 1em);
    min-width: 300px;
    border: 3px solid #f6f7fa;
    border-radius: 12px;
    transition: 0.2s;
    cursor: pointer;

    &:hover, &:focus {
      box-shadow: 0 6px 31px -2px rgb(0 0 0 / 10%);
      -webkit-transform: translate(0, -10px);
      -ms-transform: translate(0, -10px);
      transform: translate(0, -10px);
      background-color: white;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 64px;
      height: 64px;
      width: 64px;
      color: #555;

      path {
        fill: #555;
      }
    }

    .name {
      margin: 16px 0;
      color: #555;
    }

    .description {
      font-size: 14px;
      text-align: center;
      flex: 1;
      display: flex;
      align-items: center;
    }

    .actions {
      margin: 24px 0;
      display: none;
    }

    .iv {
      height: 56px;
      width: 56px;
    }
  }
}
