.container {
  background-color: white;
  padding: 24px;
  border-radius: 6px;

  @media screen and (max-width: 600px) {
    padding: 16px;
  }

  @media screen and (max-width: 480px) {
    padding: 8px;
  }

  .header { 
    margin-bottom: 8px;
  }

  .title {
    margin: 0;
    font-weight: 500;
  }
}
