.container {
  display: flex;
  align-items: center;

  background-color: white;
  padding: 16px;
  margin-bottom: 24px;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.graphicContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 16px;
}

.graphic {
  height: 170px;
  width: 340px;
  border-radius: 170px 170px 0 0;
  background-color: #cad8e2;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .itemContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 180px;
  }

  .separator {
    width: 100%;
    height: 1px;
    background-color: #bbb;
  }

  .item {
    width: 180px;
    height: 56px;
    display: flex;
    flex-direction: column;
    padding: 16px;

    .titleSection {
      width: 78px;
      height: 12px;
      background-color: #bbb;
      border-radius: 8px;
    }

    .descriptionSection {
      width: 120px;
      height: 12px;
      background-color: #ddd;
      border-radius: 8px;
      margin-top: 8px;
    }
  }
}

.contentContainer {
  flex: 1;
  padding: 16px;
  display: flex;
  align-items: center;
}

.content {
  max-width: 400px;

  .title {
    text-align: center;
    margin: 0;
    font-weight: 500;
  }

  .description {
    margin-top: 16px;
    color: #555;
    text-align: center;
  }

  .actions {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}