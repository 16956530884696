.container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 24px;
  color: #555;
  margin: 0 0 16px 0;
  border-radius: 8px;

  .header {
    .title {
      color: #555;
      margin: 0;
    }
  }

  .info {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 16px;
  }
}
