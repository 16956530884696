.memberItem {
  padding: 12px 4px;
  border-bottom: 1px solid #eee;
  border-radius: 6px;
  height: 70px;

  &.editing {
    padding: 6px 4px;

    .detailsContainer {
      .actions {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .detailsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .avatar {
      margin-right: 12px;

      @media all and (max-width: 600px) {
        display: none;
      }
    }

    .nameContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 2;
      font-weight: 500;
      overflow: hidden;

      .email {
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;

        &.nameVisible {
          color: #555;
        }
      }
    }

    .details {
      flex: 1;
      display: flex;
      justify-content: center;
    }

    .actions {
      border-radius: 0 6px 6px 0;
      opacity: 0;
      visibility: hidden;
      display: flex;
      flex-direction: row;

      @media all and (max-width: 600px) {
        display: none;
      }

      .progressContainer {
        display: flex;
        align-items: center;
      }
    }
  }

  &:hover,
  &:focus,
  &:focus-within {
    outline: 1px solid #6ac2f3;

    .actions {
      display: flex;
      visibility: visible;
      opacity: 1;
      transition: all 0.5s ease-in-out;
    }
  }
}
