#bongo {
    margin: 0;
    padding: 0;
    height: 100%;
    margin-top: 52px;
}

#bongo-iframe {
    border-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}
