.courseItem {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eee;

  .details {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding: 24px 16px;

    .nameContainer {
      flex: 1;
      display: flex;
      flex-direction: column;

      .name {
        flex: 1;
        font-size: 18px;
        margin-bottom: 2px;
      }

      .enrollmentContainer {
        display: flex;
      }
    }

    .role,
    .org,
    .enrollment {
      flex: 1;
      text-align: center;
    }

    .enrollment {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .actionsContainer {
    width: 64px;
    display: flex;

    .menuButton {
      flex: 1;
      padding: 0;
      min-width: unset;
    }
  }
}
