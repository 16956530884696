@import 'common/styles/vars.scss';

.page {
  height: 100vh;
  background: $bongo-page-gradient;
  max-width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo {
    padding: 16px;
    width: 220px;
  }

  .terms {
    background-color: white;
    min-width: 300px;
    max-width: 750px;
    border-radius: 8px;
    padding: 32px;
    margin: 0 16px;

    h1 {
      text-align: center;
    }

    .infoSection {
      margin: 28px 0;

      .link {
        display: inline-flex;
      }

      .popoutIcon {
        align-self: center;
        margin-left: 1px;
      }
    }

    .acceptSection {
      text-align: center;
      font-weight: 500;
      margin-top: 24px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;

    .action {
      margin: 0 16px;
    }
  }
}
