.appBar {
  .actionsContainer {
    display: flex;

    .backButton {
      flex: 0;
      color: white;
      font-size: 24px;
    }
  }

  .username {
    font-size: 24px;
    margin-left: 16px;
  }
}
