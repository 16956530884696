.container {
  .contents {
    margin: 8px 0;
  }

  .actions {
    margin-top: 16px;
    display: flex;
    justify-content: center;

    .copyButton {
      width: 100%;
    }
  }
}