@import 'common/styles/vars.scss';

.container {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      flex: 1;
    }
  }
}

.contents {
  margin: 8px 0;

  .paper {
    padding: 4px 8px 16px 8px;
  }

  .toggle {
    padding: 12px 0;
  }

  .dateContainer {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
  }

  .dateItem {
    flex: 1;
    display: flex;
  }

  .dateItem:first-child {
    margin-right: 8px;
  }

  .validationMessage {
    margin-top: 8px;
    color: $bongo-error-color;
    font-weight: 500;
  }

  .actions {
    display: flex;
    margin-top: 16px;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
