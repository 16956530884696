.appBar {
  .toolbar {
    min-height: 48px;
  }
}

.leftContent {
  flex: 1;
}

.rightContent {
  display: flex;
  flex-direction: row;
  align-items: center;

  .userOptions {
    padding: 2px 4px;

    .avatar {
      font-size: 1.2rem;
    }
  }
}


.userMenuContainer {
  min-width: 200px;
  max-width: 300px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .userInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;

    .avatar {
      font-size: 1.6rem;
    }

    .username {
      margin-top: 16px;
    }

    .email {
      color: #aaa;
      word-break: break-all;
      text-align: center;
    }
  }

  .actions {
    width: 100%;
    padding: 0;
    // margin-top: 8px;

    .actionItem {
      border-top: 1px solid #ddd;
      padding: 0;
    }
  }
}
