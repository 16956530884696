.iconWrapper {
  --iconSize: 28px;

  height: var(--iconSize);
  width: var(--iconSize);
  font-size: var(--iconSize);
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: 100%;
  }
}
