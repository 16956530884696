.container,
.content
{
  margin: 0 auto;
}

.container {
  padding: 0 8px;
}

@media all and (min-width: 1px) {
  .container {
    padding: 0;
  }
}


@media all and (min-width: 401px) {
  .container {
    padding: 8px;
    min-width: 300px;
  }
}

@media all and (min-width: 501px) {
  .container {
    padding: 0 24px;
    min-width: 300px;
  }
}

@media all and (min-width: 768px) {
  .container {
    padding: 0 32px;
    min-width: 428px;
    max-width: 1366px;
  }
}

.page {
  // prevent horizontal scrolling
  background: none;
  margin: 0;
  padding: 0;
  display: block;
  margin-top: 56px;
  min-height: 100vh;
  background-color: var(--bsa-theme-color-page-background);

  .container {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .header {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .titleContainer {
    flex: 1;

    .breadcrumbs {
      padding: 2px;
    }

    .crumb {
      color: var(--bsa-theme-color-page-contrast);
    }

    .crumbSeparator {
      margin: 0 6px;
      color: var(--bsa-theme-color-page-contrast);
    }

    .titleContents {
      display: flex;

      .titleActions {
        margin-left: 8px;
        align-self: center;
      }
    }
  }

  .title {
    color: var(--bsa-theme-color-page-contrast);
    font-size: var(--bsa-theme-font-page-h1-size);
    line-height: var(--bsa-theme-font-page-h1-size);
    font-weight: 500;
    margin: 8px 0;
    word-break: break-all;
  }

  .descriptionContainer {
    color: var(--bsa-theme-color-page-contrast);
    padding: 2px;
    margin-left: 2px;
    width: 100%;
  }

  .mainContentCard {
    width: 100%;
  }

  .content {
    align-self: center;
    flex: 1;
    padding: 16px 0;
    position: relative;
    width: 100%;
    min-height: 120px;
  }

  .actionsContainer {
    align-self: flex-start;
  }
}
