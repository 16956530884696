.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.graphic {
  height: 150px;
  width: 300px;
  border-radius: 150px 150px 0 0;
  background-color: #cad8e2;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .itemContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 140px;
  }

  .separator {
    width: 100%;
    height: 1px;
    background-color: #bbb;
  }

  .item {
    height: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;

    .icon {
      color: #888;
    }

    .itemContent {
      flex: 1;
      padding: 8px;
      display: flex;
      flex-direction: column;

      .titleSection {
        // width: 84px;
        height: 12px;
        background-color: #bbb;
        border-radius: 8px;
      }
    }
  }
}

.content {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;

  .title {
    margin: 0;
    font-weight: 500;
  }

  .description {
    margin-top: 16px;
    text-align: center;
    color: #555;
  }

  .createButton {
    margin-top: 16px;
    margin-bottom: 32px;
  }
}
