.tooltipContainer {
  .tooltipMessage {
    text-align: center;
  }

  .tooltipDate {
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
}

.enrollment {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 24px;

  color: #000;
  background-color: #ddd;
  border-radius: 6px;
  padding: 0 10px;
  font-size: 13px;

  .enrollmentLabel {
    white-space: nowrap;
    margin-right: 6px;
    font-weight: 500;
  }

  .enrollmentItem {
    display: flex;
    align-items: center;

    .itemLabel {
      font-weight: 500;
    }

    .calIcon {
      margin-left: 4px;
    }
  }

  &.enabled {
    background-color: #d1fac8;

    .enrollmentLabel {
      color: #333;
    }

    .enrollmentItem {
      color: #0c760c;
    }
  }
}
