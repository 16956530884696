.dialog {
  .actions {
    justify-content: center;
    padding-bottom: 16px;
  }
}

.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  .titleContainer {
    flex: 1;

    .title {
      flex: 1;
      margin: 0;
      font-size: 24px;
    }

    .description {
      margin: 0;
      font-size: 16px;
      color: #555;
    }
  }

  .buttonContainer {
    align-self: center;
    .closeButton {
      margin-left: 24px;
    }
  }
}
