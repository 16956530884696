.courseItem {
  display: flex;
  flex-direction: row;

  .details {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding: 16px;

    .name {
      flex: 1,
    }

    .role, .org {
      flex: 1;
      text-align: center;
    }
  }

  .actionsContainer {
    width: 64px;
    display: flex;

    .menuButton {
      flex: 1;
      padding: 0;
      min-width: unset;
    }
  }
}