.envDialog {

  .content {
    min-width: 350px;
  }

  .envEntries {
    display: flex;
    flex-direction: column;
    flex: 1;

    .envEntry {
      display: flex;
      flex-direction: row;
      margin-bottom: 6px;

      .envEntryLabel {
        width: 130px;
        font-weight: bold;
        text-align: right;
        margin-right: 4px;
      }

      .envEntryValue {
        word-break: break-all;
        flex: 1;
      }
    }
  }

  .envActions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
  }

  .logLevel {
    display: flex;
    align-items: center;

    .levelSelect {
      margin-right: 16px;
      min-width: 160px;
    }
  }
}
