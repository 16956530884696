.dialog {
  .dialogContent {
    padding: 0 24px 24px 24px;

    @media screen and (max-width: 1100px) {
      padding: 0 16px 16px 16px;
    }

    @media screen and (max-width: 1000px) {
      padding: 0 8px 8px 8px;
    }

    @media screen and (max-width: 768px) {
      padding: 0 16px;
    }
  }
}

.content {
  display: flex;
  flex-direction: row;
  color: #353535;
  letter-spacing: 0.03rem;
  justify-content: center;
  flex-flow: wrap;

  .leftContent {
    display: flex;
    flex: 1.2;
    flex-direction: column;
    max-width: 500px;
    min-width: 300px;

    @media screen and (max-width: 1100px) {
      max-width: unset;
    }

    .actions {
      visibility: hidden;
      background-color: #e8f3ff;
      padding: 6px;
      display: flex;
      justify-content: flex-end;

      .cancel {
        margin-right: 16px;
      }

      &.visible {
        visibility: visible;
      }
    }

    .sections {
      padding: 16px 42px 24px 42px;

      @media screen and (max-width: 1100px) {
        padding: 16px 32px 8px 8px;
      }

      @media screen and (max-width: 1000px) {
        padding: 16px 16px 8px 8px;
      }

      @media screen and (max-width: 768px) {
        padding: 16px;
      }
    }

    .enrollmentSection {
      margin-top: 48px;
    }
  }

  .rightContent {
    flex: 2;
    padding: 64px 48px 48px 48px;
    max-width: 900px;

    @media screen and (max-width: 768px) {
      padding: 16px;
    }
  }
}
