.container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 24px;
  color: #555;
  border-radius: 8px;

  .message {
    margin: 8px 0;
  }

  .assignments {
    display: grid;
    overflow: visible;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 18px;
    grid-row-gap: 18px;
    margin-top: 24px;

    @media screen and (max-width: 1160px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: 940px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 675px) {
      grid-template-columns: 1fr;
    }

    .assignmentType {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px;
      flex: 0 1 calc(25% - 1em);
      // min-width: 200px;
      border: 3px solid #f6f7fa;
      border-radius: 12px;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 36px;
        height: 36px;
        width: 36px;
        color: #555;

        path {
          fill: #555;
        }
      }

      .name {
        margin: 16px 0;
        font-size: 18px;
        color: #555;
      }

      .description {
        font-size: 14px;
        text-align: center;
        flex: 1;
        display: flex;
        align-items: flex-start;
      }

      .actions {
        margin: 24px 0;
        display: none;
      }

      .iv {
        height: 36px;
        width: 36px;
      }
    }
  }

  .bongoLink {
    display: flex;
    flex-direction: row;
    align-items: center;

    .linkText {
      margin-right: 4px;
    }
  }
}
