@import 'common/styles/vars.scss';

.content {
  .nameContent {
    margin: 8px 0;

    .nameForm {
      display: flex;
      flex-direction: row;

      .nameInput {
        flex: 1;
      }

      .accept {
        min-width: 32px;
        margin-right: 4px;
        margin-left: 4px;
      }

      .cancel {
        min-width: 32px;
      }
    }
  }

  .validationMessage {
    margin-top: 4px;
    color: $bongo-error-color;
    font-weight: 500;
  }
}
