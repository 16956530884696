.page {
  .container {
    height: calc(100vh - 64px);
  }

  .sep {
    width: 1px;
    background-color: #ccc;
    height: 18px;
    margin: 0 12px;
  }

  .dotSep {
    height: 8px;
    width: 8px;
    background-color: #ccc;
    border-radius: 4px;
    margin: 0 8px;
    // display: none;
  }

  .editMembersButton {
    margin-left: 6px;
  }

  .actions {
    display: flex;
    align-items: center;
  }

  .avatarItem {
    color: red;
  }

  .courseDetails {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0 0 16px 0;

    .emptyMembers {
      display: flex;
      flex-direction: row;
      align-items: center;

      .message {
        margin-left: 8px;
        font-size: 14px;
        font-style: italic;
      }
    }

    .memberInfo {
      display: flex;
      align-items: center;
      // margin-left: 24px;

      .memberLabel {
        margin-right: 6px;
        font-weight: 500;
        font-size: 14px;
        color: #555;
      }
    }
  }
}
